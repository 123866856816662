/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Products from 'components/Products'
import { ButtonAlt, BannerCustom, ProductBanner } from 'components/Elements/Elements'
import BrandSlider from 'components/Brands/BrandSlider'
import CustomLink from 'components/CustomLink'

// CSS
import 'styles/Soort.scss';

// Images
import whiteArrow from 'img/white_arrow.svg'

// Third Party
import parse from 'html-react-parser'

export const SoortTemplate = (
  { 
    slug, 
    name, 
    producten, 
    bannerImage, 
    bigBanner: {
      banner_image: {
        localFile: {
          childImageSharp: {
            fluid: bigBannerImage
          }
        }
      },
      banner_text: bigBannerText,
      banner_button_text: bigBannerButtonText
    },
    banner2
  }) => {
  
  const handleChange = (e) => {
    navigate(`/producten/${slug}/${e.target.value}/#a`)
  }

  return (
    <section className="section soort">
      <div className="soort-top">
        <div className="row position-relative">
          <div className="image-container col-12">
            <BackgroundImage fluid={bannerImage} />
            <div className="grey-empty-banner" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex">
                <div className="image-text-container d-flex flex-wrap w-100 pt-lg-4 pt-0">
                  <h1 className="font-size-xl uppercase pr-3 mb-4 font-weight-xl color-text-light m-0">{name}</h1>
                  <div className="product-row mt-3 mb-4 w-100 d-none d-lg-flex justify-content-between">
                    <Products row />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="product-mobile-top container pt-4 d-flex flex-wrap d-lg-none justify-content-center mb-3 w-100">
          <CustomLink to='/contact' className="product-contact-button w-100 px-3 mb-3 py-4 color-background-contrast d-flex justify-content-center align-items-center ctabanner-button">
            <p className="color-text-light font-weight-xl mb-0 text-uppercase">{bigBannerButtonText}</p>
            <img className="pl-2" alt="arrow" src={whiteArrow} />
          </CustomLink>
          <label className="product-mobile-top-selectcontainer w-100">
            <select onChange={handleChange} className="product-mobile-top-select w-100 pl-3 color-text-main mb-3 py-3">
              <option value={null} className="product-mobile-top-option">Kies een product</option>
              {producten.map((product) => (
                <option value={product.post_name} className="product-mobile-top-option">{parse(product.post_title)}</option>
              ))}
            </select>
          </label>
          <Img className="product-mobile-top-image" fluid={bigBannerImage} alt="" />
        </div>
      </div>
      <div className="big-image-container d-lg-block d-none pt-3 pb-5 position-relative">
        <BackgroundImage fluid={bigBannerImage} />
        <div className="position-absolute m-y-minus-per-10 z-20 ab-25 d-flex flex-wrap text-center justify-content-center w-100">
          <h2 className="font-size-xl w-50 font-weight-xl color-text-light">{bigBannerText}</h2>
          <div className="pt-4 w-100">
            <ButtonAlt to="/contact">
              {bigBannerButtonText}
              <img className="pl-2" alt="arrow" src={whiteArrow} />
            </ButtonAlt>
          </div>
        </div>
        <div className="gradient position-absolute w-100 h-100" />
      </div>
      <ProductBanner slug={slug} producten={producten} className="color-background-secondary d-lg-flex d-none" />
      {/* <div className="empty-space-200 d-lg-block d-none" /> */}
      <BannerCustom to="/contact" className="color-background-main" bannerText={banner2.text} bannerButtonText={banner2.button_text} />
      <div className="container d-block d-lg-none py-5">
        <Products title="Bekijk meer producten" />
      </div>
      <BrandSlider title={`Onze Merken ${name}`} className="py-5" />
    </section>
  )
}

const Soort = ({ data }) => {

  const { wordpressWpSoorten: soort } = data

  return (
    <Layout noPhone>
      <SEO title={`${soort.name} W- Kooloos`} />
      <SoortTemplate
        slug={soort.slug}
        name={soort.name}
        producten={soort.producten}
        bannerImage={soort.acf.banner_image.localFile.childImageSharp.fluid}
        bigBanner={soort.acf.big_banner}
        banner2={soort.acf.banner_2}
      />
    </Layout>
  )
}

Soort.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Soort

export const pageQuery = graphql`
  fragment PostFields on wordpress__wp_soorten {
    id
    slug
  }
  query DetailSoortQuery($id: String!) {
    wordpressWpSoorten(id: {eq: $id}) {
      slug
      name
      acf {
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        big_banner {
          banner_image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          banner_text
          banner_button_text
        }
        banner_2 {
          text
          button_text
        }
      }
      producten {
        post_title
        post_name
      }
    }
  }
`
